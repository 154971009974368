'use strict';
/**
* @desc Controling options navigation
* @author Kim Dolleris
* @example novicell.navigationOptions.init();
* @requires none
*/

var novicell = novicell || {};

novicell.navigationOptions = novicell.navigationOptions || function(){

    function init() {
        optionsShowHide();
    }

    function optionsShowHide(){
        $('#navigation-options__trigger-js').on('click', function(){
            $('#navigation-options__list-js, #navigation-options__trigger-js').toggleClass('active');
        });
    }

    return {
        init: init
    };

}();