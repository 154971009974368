'use strict';
/**
* @desc Controling main navigation
* @author Kim Dolleris
* @example novicell.navigationMain.init();
* @requires none
*/

var novicell = novicell || {};

novicell.navigationCategories = novicell.navigationCategories || function(){

    function init() {
        removeSpecificCategory();
        navigationCategoriesReponsive();
        navigationCategoriesClick();
        timelineClick();
        faqClick();
        currentlyClick();
        aboutClick();
    }


    function navigationCategoriesReponsive(){

        var $categoryItems = $('.navigation-categories__item').length;

        $('#navigation-categories__list-js').owlCarousel({
            margin:0,
            responsiveClass:true,
            navText:['‹','›'],
            responsive:{
                0:{
                    items:2,
                    nav:true
                },
                480:{
                    items:3,
                    nav:true,
                    loop:false
                },
                768:{
                    items:$categoryItems,
                    nav:false,
                    loop:false
                }
            }
        });

    }

    function navigationCategoriesClick(){

        $.ajaxSetup({cache:false});

        $('.navigation-categories__item button').on('click', function(e){
            console.log('weee clicked');
            e.preventDefault();

            var post_permalink   = $(this).data('post-permalink');

            $('#modal-loading-js').show();
            $('#modal-js').addClass('active');
            $('#backdrop').addClass('active');

            $('#modal-content-js').load(post_permalink, function(){
                $('#modal-loading-js').delay(500).fadeOut(500);

                setTimeout(function(){
                    if ($('#js-faq-list').length == 0) {
                        $('#modal-js').addClass('modal--no_faq');
                    }
                    if ($('#js-image-slider').length) {
                        singleCategoryImageSlider();
                    }
                }, 500);
            });

            return false;

        });

    }

    function timelineClick(){

        $.ajaxSetup({cache:false});

        $('#options-timeline button').on('click', function(e){
            console.log('weee clicked');
            e.preventDefault();

            var timeline_permalink   = $(this).data('timeline-permalink');

            $('#modal-loading-js').show();
            $('#modal-js').addClass('active');
            $('#backdrop').addClass('active');

            $('#modal-content-js').load(timeline_permalink, function(){
                $('#modal-loading-js').delay(500).fadeOut(500);
            });

            return false;

        });

    }

    function faqClick(){

        $.ajaxSetup({cache:false});

        $('#options-answers button').on('click', function(e){
            console.log('weee clicked');
            e.preventDefault();

            var timeline_permalink   = $(this).data('faq-permalink');

            $('#modal-loading-js').show();
            $('#modal-js').addClass('active');
            $('#backdrop').addClass('active');

            $('#modal-content-js').load(timeline_permalink, function(){
                $('#modal-loading-js').delay(500).fadeOut(500);
            });

            return false;

        });

    }

    function currentlyClick(){

        $.ajaxSetup({cache:false});

        $('#options-currently button').on('click', function(e){
            console.log('weee clicked');
            e.preventDefault();

            var currently_permalink   = $(this).data('currently-permalink');

            $('#modal-loading-js').show();
            $('#modal-js').addClass('active');
            $('#backdrop').addClass('active');

            $('#modal-content-js').load(currently_permalink, function(){
                $('#modal-loading-js').delay(500).fadeOut(500);
            });

            return false;

        });

    }

    function aboutClick(){

        $.ajaxSetup({cache:false});

        $('#options-about button').on('click', function(e){
            console.log('weee clicked');
            e.preventDefault();

            var about_permalink   = $(this).data('about-permalink');

            $('#modal-loading-js').show();
            $('#modal-js').addClass('active');
            $('#backdrop').addClass('active');

            $('#modal-content-js').load(about_permalink, function(){
                $('#modal-loading-js').delay(500).fadeOut(500);
            });

            return false;

        });

    }

    function singleCategoryImageSlider(){
        $('#js-image-slider').owlCarousel({
            margin: 0,
            dots: true,
            dotsContainer: '.image-slider__thumbnails',
            items:1,
            autoHeight: true,
            nav: false,
            loop: false,
            navText: ["‹","›"]
        });
        $('.owl-dot').click(function () {
            $('#js-image-slider').trigger('to.owl.carousel', [$(this).index(), 300]);
        });
    }

    function removeSpecificCategory() {
        $('.navigation-categories__item.remove-category').remove();
    }

    return {
        init: init,
        singleCategoryImageSlider : singleCategoryImageSlider
    };

}();