'use strict';
/**
* @desc Controling modals
* @author Kim Dolleris
* @example novicell.modal.init();
* @requires none
*/

var novicell = novicell || {};

novicell.modal = novicell.modal || function(){

    function init() {
        modal();
    }

    function modal(){
        $('#modal__close-btn-js').on('click', function(){
            $('#modal-js').removeClass('active modal--no_faq');
            $('#backdrop').removeClass('active');
            $('#modal-content-js').html('');
        });
    }

    return {
        init: init
    };

}();