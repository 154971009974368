'use strict';
/**
* @desc Controling main navigation
* @author Kim Dolleris
* @example novicell.navigationMain.init();
* @requires none
*/

var novicell = novicell || {};

novicell.singleCategory = novicell.singleCategory || function(){

    function init() {
        singleCategoryImageSlider();
    }


    function singleCategoryImageSlider(){
        $('.category__image-slider').owlCarousel({
            margin: 0,
            dots: true,
            // dotsContainer: '#single-category-thumbnail',
            items:1,
            nav: false,
            loop: false,
            navText: ["‹","›"]
        });
        // $('.owl-dot').click(function () {
        //     $('.category__image-slider').trigger('to.owl.carousel', [$(this).index(), 300]);
        // });
    }

    return {
        init: init
    };

}();