'use strict';
/*
* Novicell master
*  Author: APN modified by DLA
*  Use this file to call functions in other files, on document ready, window.resize etc.
*  ------
* Dependencies: jquery, novicell.js, other files with awesome functions.
*  Usage: $(function () {
*              projectName.slider.heroSlider($('.owl-carousel'));
*         }); // Document ready end
*/



var novicell = novicell || {};
var TryghedsrenoveringenBispehaven = TryghedsrenoveringenBispehaven || {};

// Document ready
$(document).ready(function() {
    // Only include Novicell functions that you use!
    novicell.navigationCategories.init();
    novicell.navigationOptions.init();
    novicell.modal.init();
    // novicell.singleCategory.init();

    // Call new functions here like this:
    svg4everybody(); // Fix SVG spritemap in IE/Edge

    // novicell.font.webfont({
    //     google: { families: ['Open+Sans:300,400,700:latin'] },
    //     custom: { families: ['AFDiwa-Light'], urls: ['/dist/css/webfont.min.css'] }
    // });

    console.log('Ready!');

}); // Document ready end


$(window).on('load', function () {

    console.log('and...Loaded!');

}); // Page fully loaded